<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        <Input clearable placeholder="输入job name搜索" class="search-input" @on-clear="handleSearch" v-model.trim="queryinfo.name"/>
        <Input clearable placeholder="输入执行命令搜索" class="search-input" @on-clear="handleSearch" v-model.trim="queryinfo.job_cmd"/>
        <Button @click="handleSearch" class="search-btn" type="primary">搜索</Button>
        <slot name="new_btn">
          <Button type="primary" @click="addmodel('post')" class="search-btn">新建</Button>
        </slot>
      </div>
      <Table size="small" ref="selection"  :columns="columns" :data="tableData"></Table>

      <Modal
          v-model="showaddmodel"
          :title="addtitile[method]"
          width="800"
          footer-hide>
        <div class="editor-container">
          <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="90">
            <FormItem label="job name" prop="name">
              <Input v-model.trim="formValidate.name" placeholder="请输入定时任务ID，建议使用有意义的英文命名，名且不要更改"></Input>
            </FormItem>
            <FormItem label="执行命令" prop="job_cmd">
              <Input v-model.trim="formValidate.job_cmd" placeholder="请输入要执行的命令"></Input>
            </FormItem>
            <FormItem label="定时器" prop="cron">
              <Input v-model.trim="formValidate.cron" placeholder="定时器，参考linux crontab，（秒 分 时 日 月 周）"></Input>
            </FormItem>
            <FormItem label="最大实例" prop="max_instances">
              <InputNumber :max="5" :min="1" v-model="formValidate.max_instances"></InputNumber>
            </FormItem>
            <FormItem>
              <Button @click="handleSubmit('formValidate')" type="primary" >提交</Button>
              <Button @click="cancel" style="position: absolute;left: 10%;">取消</Button>
            </FormItem>
          </Form>
        </div>
      </Modal>
    </Card>
    <br />
    <template>
      <Page :current="this.queryinfo.page"
            :total="total"
            :page-size-opts="pagesizeopts"
            @on-change="changepage"
            @on-page-size-change="changepagesize"
            show-total
            show-sizer
            style="text-align: right;" />
    </template>
  </div>
</template>

<script>
import {get,post,put,patch,del} from "@/api/http.js";
export default {
  data() {
    return {
      tableData: [],
      total: 0, // 数据总数
      pagesizeopts: [10, 20, 30, 40],
      queryinfo: {
        page: 1,
        pagesize: 10,
        name: '',
        job_cmd: '',
      },
      ruleValidate: {
        name: [{ required: true, message: 'job_name不能为空', trigger: 'blur' }],
        job_cmd: [{ required: true, message: '执行命令不能为空', trigger: 'blur' }],
        cron: [{ required: true, message: '定时器不能为空', trigger: 'blur' }],
        max_instances: [{ required: true, type: "number", message: '实例不能为空', trigger: 'blur' }],
      },
      showaddmodel: false,
      addtitile: {
        "post":"新建任务",
        "put":"编辑任务",
      },
      method: '',
      formValidate: {
        name: "",
        job_cmd: "",
        cron: "",
        max_instances: 1,
      },
      columns: [
        {type: 'selection', key: '', width: 60, align: 'center'},
        {title: 'job name', key: 'name', minWidth: 80, sortable: true},
        {title: '可执行命令', key: 'job_cmd', minWidth: 250,},
        {title: '定时器（秒 分 时 日 月 周）', key: 'cron', minWidth: 100,},
        {title: '最大实例', key: 'max_instances', minWidth: 50,},
        {title: '下一次执行时间', key: 'next_run_time', minWidth: 100,},
        {title: '状态', key: 'status', width: 80, align: 'center',
          render: (h, params, vm) => {
            return h('div', [
              h('i-switch', {
                props: {
                  size: 'large',
                  value: params.row.status === '0' // 控制开关的打开或关闭状态，官网文档属性是value
                },
                style: {
                  marginRight: '5px'
                },
                scopedSlots: {
                  open: () => h('span', '正常'),
                  close: () => h('span', '暂停')
                },
                on: {
                  'on-change': () => {
                    this.onSwitch(params)
                  }
                }
              })
            ])
          }
        },
        {title: '操作', key: 'handle', width: 170, align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                  'Button',
                  {
                    props: {
                      type: 'primary',
                      size: 'small'
                    },
                    style: {
                      marginRight: '5px'
                    },
                    on: {
                      click: () => {
                        this.editModal(params.row,'put')
                      }
                    }
                  },
                  '编辑'
              ),
              h(
                  'Button',
                  {
                    props: {
                      type: 'error',
                      size: 'small'
                    },
                    on: {
                      click: () => {
                        this.delData(params.row)
                      }
                    }
                  },
                  '删除'
              )
            ])
          }
        }
      ],
      searchValue: '',
      formList: [],
      modalMap: {
        modalVisible: false,
        modalTitle: '创建任务'
      },
    }
  },
  methods: {
    changepage (page) {
      this.queryinfo.page = page
      this.getdata(this.queryinfo)
    },
    changepagesize(pagesize) {
      this.queryinfo.page = 1
      this.queryinfo.pagesize = pagesize
      this.getdata(this.queryinfo)
    },
    handleSearch() {
      this.queryinfo.page = 1
      this.getdata(this.queryinfo)
    },
    getdata(queryinfo) {
      get("/cron/api/v1/cron/job", queryinfo)
          .then((resp) => {
            this.tableData = resp.data;
            this.total = resp.count
          }).catch(e => {
        return e
      })
    },

    addmodel(method) {
      this.method = method
      this.showaddmodel = true
    },

    cancel(){
      this.formValidate = {name: "",job_cmd: "",cron: "",max_instances: 1}
      this.showaddmodel = false
    },

    editModal(row,method){
      this.method = method
      this.formValidate.name = row.name
      this.formValidate.job_cmd = row.job_cmd
      this.formValidate.cron = row.cron
      this.formValidate.max_instances = row.max_instances
      this.showaddmodel = true
    },

    // 调用开关
    onSwitch (params) {
      this.patchjob(params.row.name)
    },

    addjob(data) {
      post("/cron/api/v1/cron/job", data)
          .then((resp)=>{
            this.getdata(this.queryinfo)
            this.showaddmodel = false
            this.formValidate = {name: "",job_cmd: "",cron: "",max_instances: 1}
          })
          .catch((e)=>{
            this.$Message.error(e);
            return e
          })
    },

    delcron(name){
      const url = '/cron/api/v1/cron/job?name='+name
      del(url)
          .then((resp)=>{
            this.getdata(this.queryinfo)
          })
          .catch((e)=>{
            this.$Message.error(e);
            return e
          })
    },

    updatejob(data) {
      put("/cron/api/v1/cron/job", data)
          .then((resp)=>{
            this.getdata(this.queryinfo)
            this.showaddmodel = false
            this.formValidate = {name: "",job_cmd: "",cron: "",max_instances: 1}
          })
          .catch((e)=>{
            this.$Message.error(e);
            return e
          })
    },
    patchjob(name) {
      patch(`/cron/api/v1/cron/job?name=`+name)
          .then((resp) => {
          }).catch(e => {
        return e
      })
      this.getdata(this.queryinfo)
    },

    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.method === "post") {
            this.addjob(this.formValidate)
          } else {
            this.updatejob(this.formValidate)
          }
        } else {
          this.$Message.error('请完善表单信息');
        }
      })
    },

    delData (row) {
      if (confirm(`     谨慎删除，删除后，相关日志也会清除掉!!!
确定要删除 ${row.name}?`)) {
        this.delcron(row.name)
      }
    },
  },
  mounted () {
    this.getdata(this.queryinfo)
  }
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
